<template>
  <div>
    <NutritionMacronutrientSlider
      @rawValueUpdate="$emit('macroUpdate', { type: 'c', value: $event })"
      @percentageUpdate="checkPercentage('c', $event)"
      v-model="value.c.value"
      :calories="value.total.value"
      :nutrientLabel="'Carb'"
      :multiplier="4"
      class="my-1"
    ></NutritionMacronutrientSlider>
    <NutritionMacronutrientSlider
      @rawValueUpdate="$emit('macroUpdate', { type: 'p', value: $event })"
      @percentageUpdate="checkPercentage('p', $event)"
      v-model="value.p.value"
      :calories="value.total.value"
      :nutrientLabel="'Protein'"
      :multiplier="4"
      class="my-1"
    ></NutritionMacronutrientSlider>
    <NutritionMacronutrientSlider
      @rawValueUpdate="$emit('macroUpdate', { type: 'f', value: $event })"
      @percentageUpdate="checkPercentage('f', $event)"
      v-model="value.f.value"
      :calories="value.total.value"
      :nutrientLabel="'Fat'"
      :multiplier="9"
      class="my-1"
    ></NutritionMacronutrientSlider>
    <div v-if="portionIsOverLimit" class="text-danger small">
      <strong>Warning:</strong>
      Your total macronutrients must not exceed 100%.
    </div>
  </div>
</template>

<script>
export default {
  name: 'NutritionMacronutrientSliderGroup',
  components: {
    NutritionMacronutrientSlider: () => import('@/components/nutrition/NutritionMacronutrientSlider'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    portionIsOverLimit(newValue) {
      this.$emit('percentageUpdate', newValue);
    },
  },
  computed: {
    portionIsOverLimit() {
      const { c, p, f } = this.portion;
      return (c + p + f) > 100;
    },
  },
  methods: {
    checkPercentage(type, perc) {
      this.portion[type] = perc;
    },
  },
  data() {
    return {
      portion: {
        c: 0,
        p: 0,
        f: 0,
      },
    };
  },
};
</script>
